import React, { useState, useEffect } from 'react';
import CompileComponent from '../components/CompileComponent';
import Register from '../components/Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import mainPic from '../images/mainPic.png'
import coin from '../images/logo2.png'
import Chains from '../components/chains';
import Benefits from '../components/benefits';
import SocialMedia from '../components/socialMedia';
import About from '../components/about';
import Footer from '../components/footer';
import Home from './Home';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const Main = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [_navbarOpen, set_navbarOpen] = useState(0);

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="cont">
                        <div class="headers">

                            <div class="h1">
                                <div class="logoDiv">
                                    Tokeniza
                                </div>

                                <div class="connect">
                                    <div><Link activeClass="" id="fontSize" to="chains" spy={true} smooth={true} duration={550}>Chains</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="tokenize" spy={true} smooth={true} duration={550}>Tokenize</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="register" spy={true} smooth={true} duration={550}>Connect</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
                                    <div id="fontSize" onClick={() => window.location.href = 'nft'}>NFT</div>

                                    <div class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'token-dashboard'}>DASHBOARD</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {_navbarOpen < 1 ?
                            (<div class="miniBarMain">
                                <div class="logoDiv">Tokeniza</div>

                                <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                            </div>) :
                            (<div class="miniBarMain">
                                <div class="logoDiv">Tokeniza</div>

                                <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                            </div>)}

                        {_navbarOpen > 0 ?
                            (<div class="littleNav">
                                <div><Link activeClass="" id="fontSize" to="chains" spy={true} smooth={true} duration={550}>Chains</Link></div>
                                <div><Link activeClass="" id="fontSize" to="tokenize" spy={true} smooth={true} duration={550}>Tokenize</Link></div>
                                <div><Link activeClass="" id="fontSize" to="register" spy={true} smooth={true} duration={550}>Connect</Link></div>
                                <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
                                <div id="fontSize" onClick={() => window.location.href = 'nft'}>NFT</div>

                                <div>
                                    <span class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'token-dashboard'}>DASHBOARD</button>
                                    </span>
                                </div>
                                <div class="socialIcon">
                                    <img src={twitter} />
                                    <img src={mail} />
                                    <img src={tg} />

                                </div>

                            </div>) : null}

                        <Element name="home">
                            <div class="introductionMain">

                                <img class="coinMob" src={coin} />

                                <div class="introduction">
                                    <div class="in1">YOUR GATEWAY TO DIGITAL INNOVATION</div>
                                    <div class="in2">EMPOWER YOUR IDEAS,<br /> CREATE TOKENS WITH US</div>
                                    <div class="in3">Unlocking the Future of Innovation Through Seamless Token Creation and Management</div>

                                    <form class="connect2">
                                        <Link activeClass="" to="tokenize" spy={true} smooth={true} duration={550}><button class="connectBtn2">Explore</button></Link>
                                    </form>

                                </div>

                                <img class="coin" src={coin} />
                            </div>
                        </Element>
                    </div>

                    <Element name="chains">
                        <Chains />
                    </Element>

                    <Element name="tokenize">
                        <Benefits />
                    </Element>

                    <Element name="register">
                        <Home />
                    </Element>

                    <SocialMedia />

                    <Element name="about">
                        <About />
                    </Element>

                    <Footer />

                </div>
            </div>

        </>
    );
};

export default Main;
