import React, { useState, useEffect } from 'react';
import CompileComponent from '../components/CompileComponent';
import Register from '../components/Register';
import '../components/payment.css';
import { paymentContractAddressPolygon } from '../config';
import { paymentContractAddressEth } from '../config';
import { paymentContractAddressAvax } from '../config';
import { paymentContractAddressBnb } from '../config';
import { paymentContractAddressFtm } from '../config';
import { paymentContractAddressArb } from '../config';
import { paymentContractAddressOp } from '../config';
import { paymentContractAddressBase } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import { Link } from 'react-router-dom';
import eth from '../images/eth.png';
import matic from '../images/matic.png';
import avax from '../images/avax.png';
import bnb from '../images/bnb.png';
import ftm from '../images/ftm.png';
import arb from '../images/arb.png';
import op from '../images/op.png';
import base from '../images/base.png';

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);
  const notification = useNotification();
  const [loading, setLoadingState] = useState(false);
  const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
  const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
  const [provider, setProvider] = useState(window.ethereum);
  const [_signerAddress, setSignerAddress] = useState();
  const [_chainName, setChainName] = useState();
  const [_rpcUrls, set_rpcUrls] = useState();
  const [_blockExpolrerUrls, set_blockExplorerURL] = useState();
  const [_decimals, set_decimals] = useState();
  const [_symbol, set_symbol] = useState();
  const [_selectedChain, set_chain] = useState();
  const [contractPayment, setcontract] = useState('');
  const [_connectPressed, set_connectPressed] = useState(0);
  const [_displayChain, set_displayChain] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const handlePaymentMade = () => {
    setPaymentMade(true);
  };

  const infoNotification = (accountNum) => {
    notification({
      type: 'info',
      message: accountNum,
      title: `Connected to ${_chainName}`,
      position: 'topR'
    });
  };

  const checkEligibility = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      //  let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      setSignerAddress(signerAddress);

      const getnetwork = await provider.getNetwork();
      console.log("getnetwork :" + getnetwork.chainId);


      if (getnetwork.chainId == "5") {

        // setcontract(paymentContractAddressEth);
        const contract = new ethers.Contract(paymentContractAddressEth, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        console.log("isEligibleToDeploy : " + isEligibleToDeploy);
        set_chain(5);

      }

      if (getnetwork.chainId == "80001") {

        //   setcontract(paymentContractAddressPolygon);
        const contract = new ethers.Contract(paymentContractAddressPolygon, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        console.log("isEligibleToDeploy : " + isEligibleToDeploy);
        set_chain(80001);

      } else {

        console.log("--------")
      }

      //BNB
      if (getnetwork.chainId == "56") {

        //  setcontract(paymentContractAddressBnb);
        const contract = new ethers.Contract(paymentContractAddressBnb, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(56);

      }

      //OP
      if (getnetwork.chainId == "10") {

        // setcontract(paymentContractAddressOp);
        const contract = new ethers.Contract(paymentContractAddressOp, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(10);

      }

      //AVAX
      if (getnetwork.chainId == "43114") {

        // setcontract(paymentContractAddressAvax);
        const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(43114);

      }

      //FTM
      if (getnetwork.chainId == "4002") {

        //  setcontract(paymentContractAddressFtm);
        const contract = new ethers.Contract(paymentContractAddressFtm, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(4002);

      }

      //ARB
      if (getnetwork.chainId == "42161") {

        // setcontract(paymentContractAddressArb);
        const contract = new ethers.Contract(paymentContractAddressArb, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(42161);

      }

      //BASE
      if (getnetwork.chainId == "8453") {

        // setcontract(paymentContractAddressBase);
        const contract = new ethers.Contract(paymentContractAddressBase, TwitterAbi.abi, signer);
        const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
        setIsEligibleToDeploy(isEligibleToDeploy);
        set_chain(8453);
      }

      console.log("EligibilitycontractPayment : " + contractPayment);

    } catch (err) {
      console.log(err);
    }
  }

  const getLastPaymentRecordIndex = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      //  let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      setSignerAddress(signerAddress);

      const getnetwork = await provider.getNetwork();
      console.log("getnetwork :" + getnetwork.chainId);


      //ETH
      if (getnetwork.chainId == "5") {

        setcontract(paymentContractAddressEth);

        // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
        const contract = new ethers.Contract(paymentContractAddressEth, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);

      }

      //POLYGON
      if (getnetwork.chainId == "80001") {

        setcontract(paymentContractAddressPolygon);

        // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
        const contract = new ethers.Contract(paymentContractAddressPolygon, TwitterAbi.abi, signer);
        console.log("getLastPaymentRecordIndex : " + paymentContractAddressPolygon);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);

      } else {

        console.log("--------")
      }

      //BNB
      if (getnetwork.chainId == "56") {

        setcontract(paymentContractAddressBnb);
        const contract = new ethers.Contract(paymentContractAddressBnb, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);

      }

      //OP
      if (getnetwork.chainId == "10") {

        setcontract(paymentContractAddressOp);
        const contract = new ethers.Contract(paymentContractAddressOp, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);
      }

      //AVAX
      if (getnetwork.chainId == "43114") {

        setcontract(paymentContractAddressAvax);
        const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);
      }

      //FTM
      if (getnetwork.chainId == "4002") {

        setcontract(paymentContractAddressFtm);
        const contract = new ethers.Contract(paymentContractAddressFtm, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);
      }

      //ARB
      if (getnetwork.chainId == "42161") {

        setcontract(paymentContractAddressArb);
        const contract = new ethers.Contract(paymentContractAddressArb, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);
      }

      //BASE
      if (getnetwork.chainId == "8453") {

        setcontract(paymentContractAddressBase);
        const contract = new ethers.Contract(paymentContractAddressBase, TwitterAbi.abi, signer);

        const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
        console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
        setLastPaymentRecordIndex(getLastPaymentRecordIndex);
      }

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    if (!provider) {
      // window.open("https://metamask.app.link/dapp/kudeta.io/");
      //window.location.replace("https://metamask.io");

      // Render something or redirect as needed
      console.log("provider :" + provider);
    } else {

      const handleAccountsChanged = (accounts) => {
        if (provider.chainId == _selectedChain) {
        //  infoNotification(accounts[0]);
        }
        // Just to prevent reloading for the very first time
        /*if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
          setTimeout(() => { window.location.reload() }, 3000);
        }*/
      };

      const handleChainChanged = (chainId) => {
        const hexChainId = utils.hexValue(_selectedChain); // "0x01"
        

        console.log("chainId :" + chainId);
        console.log("hexChainId :" + hexChainId);

        if (chainId != hexChainId) {


          window.location.reload();
        }

      }

      const handleDisconnect = () => {
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      selectedNetwork();

    }

  }, []);

  const disconnect = async () => {
  }

  const connectWallet = async () => {
    set_connectPressed(1);
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = _selectedChain;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(chainID),
                      chainName: _chainName,
                      rpcUrls: [_rpcUrls],
                      blockExpolrerUrls: [_blockExpolrerUrls],
                      nativeCurrency:
                      {
                        decimals: _decimals,
                        symbol: _symbol,
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  const connectBtn = async () => {
    checkEligibility();
    getLastPaymentRecordIndex();

    set_connectPressed(1);
  }

  const handleImageClick = (imageName) => {
    setSelectedImage(imageName);
    // ... (rest of your logic)
  };

  async function selectedNetwork() {

    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();

    let provider = new ethers.providers.Web3Provider(connection);

    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    setSignerAddress(signerAddress);
    console.log("ELSEconnectWallet();");

    const getnetwork = await provider.getNetwork();
    console.log("getnetwork :" + getnetwork);

    if (getnetwork.chainId == "5") {

      setSelectedImage('eth');

    }

    if (getnetwork.chainId == "80001") {

      setSelectedImage('matic');

    } else {

      console.log("--------")
    }


    if (getnetwork.chainId == "56") {

      setSelectedImage('bnb');

    }

    if (getnetwork.chainId == "10") {

      setSelectedImage('op');

    }

    if (getnetwork.chainId == "43114") {

      setSelectedImage('avax');

    }

    if (getnetwork.chainId == "4002") {

      setSelectedImage('ftm');

    }

    if (getnetwork.chainId == "42161") {

      setSelectedImage('arb');

    }

    if (getnetwork.chainId == "8453") {

      setSelectedImage('base');

    }

    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

    setIsAuthenticated(true);
    setProvider(provider);

  }

  async function setChainETH(imageName) {

    setSelectedImage(imageName);


    /* set_chain(1);
     setcontract(paymentContractAddressEth);
     setChainName('Ethereum Mainnet');
     set_symbol('ETH');
     set_rpcUrls('https://mainnet.infura.io/v3/');
     set_blockExplorerURL('https://etherscan.io');*/

    /*set_chain(5);
    setChainName('Goerli test network');
    set_symbol('ETH');
    set_rpcUrls('https://goerli.infura.io/v3/');
    set_blockExplorerURL('https://goerli.etherscan.io');*/
    console.log("Eth chain");

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");


      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 5;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(5),
                      chainName: 'Goerli test network',
                      rpcUrls: ['https://goerli.infura.io/v3/'],
                      blockExpolrerUrls: ['https://goerli.etherscan.io'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'ETH',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const web3Modal = new Web3Modal();
          const connection = await web3Modal.connect();
          let provider = new ethers.providers.Web3Provider(connection);
          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
console.log("ELSEconnectWallet();");

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainPOLY(imageName) {

    setSelectedImage(imageName);

    //set_chain(80001/*137*/);
    //setChainName('Mumbai');
    //set_symbol('Matic');
    /*set_rpcUrls('https://polygon-mainnet.infura.io');
    set_blockExplorerURL('https://polygonscan.com/');*/
    //set_rpcUrls('https://rpc-mumbai.maticvigil.com/v1/a3a160abeb239e773a4525d267cb84f3660634ed');
    //set_blockExplorerURL('https://mumbai.polygonscan.com');
    //console.log("setChainPOLY");

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 80001;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(80001),
                      chainName: 'Mumbai',
                      rpcUrls: ['https://rpc-mumbai.maticvigil.com/v1/a3a160abeb239e773a4525d267cb84f3660634ed'],
                      blockExpolrerUrls: ['https://mumbai.polygonscan.com'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'Matic',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainBNB(imageName) {
    setSelectedImage(imageName);

    /*set_chain(56);
        setChainName('BNB Smart Chain Mainnet');
        set_symbol('BNB');
        set_rpcUrls('https://bsc-dataseed.binance.org/');
        set_blockExplorerURL('https://bscscan.com/');
        console.log("setChainBNB");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 56;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(56),
                      chainName: 'BNB Chain',
                      rpcUrls: ['https://bsc-dataseed.binance.org/'],
                      blockExpolrerUrls: ['https://bscscan.com/'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'BNB',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainOP(imageName) {
    setSelectedImage(imageName);

    /*set_chain(10);
    setChainName('OP Mainnet');
    set_symbol('ETH');
    set_rpcUrls('https://mainnet.optimism.io');
    set_blockExplorerURL('https://optimistic.etherscan.io');
    console.log("setChainOP");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 10;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(10),
                      chainName: 'OP Mainnet',
                      rpcUrls: ['https://mainnet.optimism.io'],
                      blockExpolrerUrls: ['https://optimistic.etherscan.io'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'ETH',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainAVAX(imageName) {
    setSelectedImage(imageName);

    /*set_chain(43114);
    setChainName('Avalanche C-Chain');
    set_symbol('AVAX');
    set_rpcUrls('https://api.avax.network/ext/bc/C/rpc');
    set_blockExplorerURL('https://snowtrace.io');
    console.log("setChainAVAX");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 43114;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(43114),
                      chainName: 'Avalanche C-Chain',
                      rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                      blockExpolrerUrls: ['https://snowtrace.io'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'Avalanche C-Chain',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainFTM(imageName) {
    setSelectedImage(imageName);

    /*set_chain(250);
    setChainName('Fantom Opera');
    set_symbol('FTM');
    set_rpcUrls('https://rpcapi.fantom.network');
    set_blockExplorerURL('https://ftmscan.com');
    console.log("setChainFTM");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 4002;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(4002),
                      chainName: 'Fantom Testnet',
                      rpcUrls: ['https://rpc.testnet.fantom.network'],
                      blockExpolrerUrls: ['https://testnet.ftmscan.com'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'FTM',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainARB(imageName) {
    setSelectedImage(imageName);

    /*set_chain(42161);
    setChainName('Arbitrum One Mainnet');
    set_symbol('ETH');
    set_rpcUrls('https://arb1.arbitrum.io/rpc');
    set_blockExplorerURL('https://arbiscan.io/');
    console.log("setChainARB");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 42161;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(42161),
                      chainName: 'Arbitrum One Mainnet',
                      rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                      blockExpolrerUrls: ['https://arbiscan.io/'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'ETH',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  async function setChainBASE(imageName) {
    setSelectedImage(imageName);

    /*set_chain(8453);
    setChainName('Base');
    set_symbol('ETH');
    set_rpcUrls('https://developer-access-mainnet.base.org');
    set_blockExplorerURL('https://basescan.org');
    console.log("setChainBASE");*/

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      console.log("connectWallet();");

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = 8453;

        if (getnetwork.chainId != chainID) {

          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(8453),
                      chainName: 'Base',
                      rpcUrls: ['https://developer-access-mainnet.base.org'],
                      blockExpolrerUrls: ['https://basescan.org'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'ETH',
                      }
                    }
                  ]
                }.then(() => window.location.reload()));

              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);
          console.log("ELSEconnectWallet();");
          

          const getnetwork = await provider.getNetwork();
          console.log("getnetwork :" + getnetwork);


          if (getnetwork.chainId == "5") {

            setcontract(paymentContractAddressEth);

          }

          if (getnetwork.chainId == "80001") {

            setcontract(paymentContractAddressPolygon);

          } else {

            console.log("--------")
          }


          if (getnetwork.chainId == "56") {

            setcontract(paymentContractAddressBnb);

          }

          if (getnetwork.chainId == "10") {

            setcontract(paymentContractAddressOp);

          }

          if (getnetwork.chainId == "43114") {

            setcontract(paymentContractAddressAvax);

          }

          if (getnetwork.chainId == "4002") {

            setcontract(paymentContractAddressFtm);

          }

          if (getnetwork.chainId == "42161") {

            setcontract(paymentContractAddressArb);

          }

          if (getnetwork.chainId == "8453") {

            setcontract(paymentContractAddressBase);

          }

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }


      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }


  return (
    <>
      <div class="registerMain">

        <div class="connectNetworks">
          <div class="network">
            <img
              onClick={() => setChainETH('eth')}
              src={eth}
              className={selectedImage === 'eth' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainPOLY('matic')}
              src={matic}
              className={selectedImage === 'matic' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainAVAX('avax')}
              src={avax}
              className={selectedImage === 'avax' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainBNB('bnb')}
              src={bnb}
              className={selectedImage === 'bnb' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainFTM('ftm')}
              src={ftm}
              className={selectedImage === 'ftm' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainARB('arb')}
              src={arb}
              className={selectedImage === 'arb' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainOP('op')}
              src={op}
              className={selectedImage === 'op' ? 'selected' : ''}
            />
            <img
              onClick={() => setChainBASE('base')}
              src={base}
              className={selectedImage === 'base' ? 'selected' : ''}
            />
          </div>
          <p class="registerT" data-aos="fade-up" data-aos-duration="500"><span class="spanR">Choose the network that suits your vibe and hit the 'Connect' button to dive into the digital realm!</span></p>
          {_connectPressed > 0 ?
            (<button class="connectReg">Connected</button>) :
            (<button class="connectReg" onClick={connectBtn}>Connect</button>)
          }
        </div>

        {_isEligibleToDeploy == true ? (
          <CompileComponent userAddress={_signerAddress} getLastPaymentRecordIndex={_getLastPaymentRecordIndex} _ChainID={_selectedChain} />

        ) : (
          <div>

            {_connectPressed > 0 ?
              (<div>
                <Register

                  _selectedChain={_selectedChain}
                  _symbol={_symbol}
                  _decimals={_decimals}
                  _blockExpolrerUrls={_blockExpolrerUrls}
                  _rpcUrls={_rpcUrls}
                  _chainName={_chainName}
                  contractPayment={contractPayment}

                />
              </div>) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
