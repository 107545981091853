export const paymentContractAddressPolygon = "0x0695EFEF877667C9C9e2ceD16268666b9A8951A4";
export const paymentContractAddressEth = "0xd040d0ad5b5b60D41fd9fFDdA0A0Ed1F9c42d71e";
export const paymentContractAddressAvax = "0x72B9Da1BBBDb255F94989A8075e307A2B904657f";
export const paymentContractAddressBnb = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressFtm = "0xDD41eE4639E49BAC15dCeB790025688874eE3f36";
export const paymentContractAddressArb = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressOp = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressBase = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";

export const paymentContractAddressPolygonNFT = "0xE106E4196554662702F200a58c0e62C267515845";
export const paymentContractAddressEthNFT = "0xd040d0ad5b5b60D41fd9fFDdA0A0Ed1F9c42d71e";
export const paymentContractAddressAvaxNFT = "0x72B9Da1BBBDb255F94989A8075e307A2B904657f";
export const paymentContractAddressBnbNFT = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressFtmNFT = "0x560279BdDd0853718A40aF8DcD59D5d943319DE6";
export const paymentContractAddressArbNFT = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressOpNFT = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";
export const paymentContractAddressBaseNFT = "0x27aec451F5477825B4f61FF6481A91e44A1F2c6c";