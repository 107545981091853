import React, { useState, useEffect } from 'react';
import CompileComponent from '../components/CompileComponent';
import Register from '../components/Register';
import '../pages/main.css';
import '../pages/nft.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import mainPic from '../images/mainPic.png'
import coin from '../images/logo2.png'
import Chains from '../components/chains';
import Benefits from '../components/benefits';
import SocialMedia from '../components/socialMedia';
import About from '../components/about';
import Footer from '../components/footer';
import Home from './nftHome';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';
import img1 from '../images/nft.jpeg';
import img2 from '../images/3.jpg';
import Process from '../components/process';
import FAQ from '../components/faq';
import Carousel from '../components/carousel';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const NFT = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [_navbarOpen, set_navbarOpen] = useState(0);

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="cont2">
                        <div class="headers">

                            <div class="h1">
                                <div class="logoDiv">
                                    Tokeniza
                                </div>

                                <div class="connect">
                                    <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="process" spy={true} smooth={true} duration={550}>PROCESS</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="connect" spy={true} smooth={true} duration={550}>CONNECT</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
                                    <div id="fontSize" onClick={() => window.location.href = '/'}>Token</div>

                                    <div class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'nft-dashboard'}>DASHBOARD</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {_navbarOpen < 1 ?
                            (<div class="miniBarMain">
                                <div class="logoDiv"><span class="dashH" onClick={() => window.location.href = 'nft'}>Tokeniza</span>
                                </div>

                                <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                            </div>) :
                            (<div class="miniBarMain">
                                <div class="logoDiv"><span class="dashH" onClick={() => window.location.href = 'nft'}>Tokeniza</span>
                                </div>

                                <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                            </div>)}

                        {_navbarOpen > 0 ?
                            (<div class="littleNav">
                                <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
                                <div><Link activeClass="" id="fontSize" to="process" spy={true} smooth={true} duration={550}>PROCESS</Link></div>
                                <div><Link activeClass="" id="fontSize" to="connect" spy={true} smooth={true} duration={550}>CONNECT</Link></div>
                                <div><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
                                <div id="fontSize" onClick={() => window.location.href = '/'}>Token</div>

                                <div>
                                    <span class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'nft-dashboard'}>DASHBOARD</button>
                                    </span>
                                </div>
                                <div class="socialIcon">
                                    <img src={twitter} />
                                    <img src={mail} />
                                    <img src={tg} />

                                </div>

                            </div>) : null}

                        <Element name="home">

                            <div className='nftMain'>
                                <div className='nftMainH'>EternArt</div>
                                <div className='nftMainP'>Nos encargamos de la digitalización de las obras de arte conviertiendolas en NFT’s y protegiendolas a traves de cadena de bloques (blockchain) garantizando la autenticidad hacia el arrendatario y por Smart contracts el cumplimiento de los términos del arrendamiento.</div>
                                <button class="nftBtn">Compra en EternArt</button>
                            </div>

                            <Carousel />

                        </Element>
                    </div>


                    <Element name="about">
                        <div class="aboutMain">

                            <div class="aboutMain2">

                                <img class="deploy" src={img1} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT">COMO</div>
                                    <div class="aboutT2">Nos encargamos de la digitalización de las
                                        obras de arte conviertiéndolas en NFT’s y
                                        protegiéndolas a través de cadena de bloques
                                        (blockchain) garantizando la autenticidad hacia
                                        el arrendatario y por Smart contracts el
                                        cumplimiento de los términos del arrendamiento.</div>
                                </div>

                            </div>
                        </div>
                    </Element>

                    <Element name="connect">
                        <Home />
                    </Element>

                    <Element name="process">
                        <Process />
                    </Element>

                    <Element name="faq">
                        <FAQ />
                    </Element>

                    <Footer />

                </div>
            </div>

        </>
    );
};

export default NFT;
