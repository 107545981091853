import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import moneymanagement from '../images/money-management.png';
import telegram from '../images/telegram.png';
import twitter from '../images/twitter.png';
import linkedin from '../images/linkedin.png';
import mail from '../images/email.png';
import yt from '../images/youtube.png';
import discord from '../images/discord.png';
import crypto from '../images/logo2_.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SocialMedia = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="socialMain">

                <div class="header">Join our community</div>

                <div class="subBox">
                    <div class="subBox2">
                        <div class="mainT">SUBSCRIBE TO OUR <br /> NEWSLETTER</div>
                        <div class="mainT2">and get a 10% discount on Token Tool</div>
                        <button class="connectBtn2">Subscribe Now</button>
                    </div>

                    <img src={crypto} />
                </div>

                <div class="benefitsMain2" data-aos="fade" data-aos-duration="500">
                    <div class="socialMain2">
                        <div class="s2">
                            <img src={twitter} />
                            <div>
                                <div class="subT">TWITTER</div>
                                <div class="subS">Join the Community</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={discord} />
                            <div>
                                <div class="subT">DISCORD</div>
                                <div class="subS">Be a Member</div>
                            </div>
                        </div>
                    </div>

                    <div class="socialMain2">
                        <div class="s2">
                            <img src={linkedin} />
                            <div>
                                <div class="subT">LINKEDIN</div>
                                <div class="subS">Connect with Us</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={yt} />
                            <div>
                                <div class="subT">YOUTUBE</div>
                                <div class="subS">Become a Part of Us</div>
                            </div>
                        </div>
                    </div>

                    <div class="socialMain2">
                        <div class="s2">
                            <img src={mail} />
                            <div>
                                <div class="subT">EMAIL</div>
                                <div class="subS">Send ypur Message</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={telegram} />
                            <div>
                                <div class="subT">TELEGRAM</div>
                                <div class="subS">Join the community</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default SocialMedia;