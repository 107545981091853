import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

          <div class="aboutT">Frequently Asked Questions</div>

          <details >
            <summary>¿QUÉ ES UN NFT?</summary>
            <div class="faq__content">
              <p>Los tokens no fungibles (NFT) son activos criptográcos protegidos por cadena de bloques
                (blockchain), códigos de identicación y metadatos que los hacen únicos y distinguibles
                entre sí.</p>
            </div>
          </details>
          <details>
            <summary>¿QUÉ GANA LA ENTIDAD PROPIETARIA DE
              LA OBRA DE ARTE ORIGINAL ?</summary>
            <div class="faq__content">
              <p>Gana el precio pactado por el arrendamiento generado en un tiempo determinado menos
                la comision de etern.ART.</p>
              <p>Nuestra sugerencia es que el ingreso se capte en forma de renta para que la propiedad del
                NFT “original” siempre sea del museo, sin embargo los NFT’s pueden ser vendidos si así
                se decide.</p>
            </div>
          </details>
          <details>
            <summary>¿QUIÉN PUEDE MONETIZAR SUS OBRAS DE ARTE?</summary>
            <div class="faq__content">
              <p>Etern.ART puede generar NFTS de cualquier obra de arte certicada en cualquier parte
                del mundo.</p>
            </div>
          </details>

          <details>
            <summary>¿QUIÉN PUEDE ARRENDAR UN NFT DE UNA OBRA
              DE ARTE COMERCIALIZADA POR ETERN.ART?</summary>
            <div class="faq__content">
              <p>Cualquier persona o entidad que tenga una pantalla con conexion a internet que quiera
                arrendar un NFT de una obra de arte previamente autorizada por etern.ART.</p>
            </div>
          </details>

          <details>
            <summary>¿DÓNDE SE COLOCAN LOS NFT’s?</summary>
            <div class="faq__content">
              <p>Hoteles , lobbys , habitaciones , ocinas o ¡hasta en tu casa! Cualquier persona o entidad
                que quiera arrendar por un tiempo determinado el NFT de una obra original y exhibirla en
                cualquier pantalla.</p>
            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

