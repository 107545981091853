import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import eth from '../images/ethereum.svg';
import poly from '../images/polygon-pos.svg';
import avalanche from '../images/avalanche.svg';
import bnb from '../images/bnb.svg';
import fantom from '../images/fantom.svg';
import arbitrum from '../images/arbitrum.svg';
import optimism from '../images/optimism.svg';
import base from '../images/base.svg';
import polyzk from '../images/polygon-zkevm.svg';
import Aos from 'aos';

const Chains = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <div className='chainsBg'>
            <div class="chainsMain" data-aos="fade-up" data-aos-duration="500">

                <div class="header"><span className='chainsT'>Tokenize on leading EVM chains</span></div>

                <div class="icons">
                    <img src={eth} />
                    <img src={poly} />
                    <img src={avalanche} />
                    <img src={bnb} />
                    <img src={fantom} />
                    <img src={arbitrum} />
                    <img src={optimism} />
                    <img src={base} />
                </div>

            </div>

        </div>
    );
};

export default Chains;
