import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import moneymanagement from '../images/money-management.png';
import search from '../images/search.png';
import rise from '../images/rise.png';
import usd from '../images/usd.png';
import system from '../images/system.png';
import wallet from '../images/wallet.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Benefits = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="benefitsMain">

                <div class="header">Focus on tokenization use cases instead of coding</div>

                <div class="benefitsMain2" data-aos="fade" data-aos-duration="500">
                    <div class="b1">
                        <div><img src={moneymanagement}/>Token Tool is the leading token generator and lifecycle manager</div>
                        <div><img src={system}/>Use the convenient user interface or an API for automation (enterprise only)</div>
                    </div>

                    <div class="b1">
                        <div><img src={wallet}/>No credit card or subscription required, pay only for what you use directly from your wallet</div>
                        <div><img src={usd}/>Effortlessly create, manage and track ERC20, and ERC721 tokens</div>
                    </div>

                    <div class="b1">
                        <div><img src={search}/>Rely on our audited smart contracts instead of coding your own</div>
                        <div><img src={rise}/>Get in touch if you need our bank-grade enterprise version of Token Tool</div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default Benefits;