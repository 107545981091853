import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import settings from '../images/settings.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Process = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="processMain">

                <div class="aboutMain2">

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">PROCESO</div>
                        <div class="aboutT2">1. Firma de NDA etern.ART - Entidad poseedora de la obra.</div>
                        <div class="aboutT2">2. Firma del contrato de prestación de servicios.</div>
                        <div class="aboutT2">3. Digitalización de la colección.</div>
                        <div class="aboutT2">4. Acuñado del NFT.</div>
                        <div class="aboutT2">5. Salida a mercado.</div>
                        <div class="aboutT2">6. Marketing de la colección.</div>
                        <div class="aboutT2">7. Arrendamiento de la(s) obra(s).</div>
                        <div class="aboutT2">8. Dispersión de fondos.</div>
                    </div>

                    <img class="deploy" src={settings} data-aos="zoom-in" data-aos-duration="500" />

                </div>
            </div>

        </>
    );
};

export default Process;